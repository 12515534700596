import styled, { keyframes } from "styled-components";

const keyframe_18jn58a = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const Overall = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 12px;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  isolation: isolate;
`;

export const GridChild = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  opacity: 1;
`;

export const SeparatorHolder = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  display: ${({ noSeparator }) => noSeparator === true ? 'none' : 'block'};
`;

export const Separator = styled.div`
  border-bottom-color: #ebebeb;
  border-bottom-width: 1px;
`;

export const MainCard = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  z-index: 1;
  flex-direction: ${(props) => (props.showMap ? "row" : "column")};
  @media (min-width: 769px) and (max-width: 1023px) {
    flex-direction: column;
  }


  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CardAnchor = styled.a`
  inset: 0px;
  z-index: 1;
  position: absolute;
  border-radius: 12px;
`;

export const PhotoHolder = styled.div`
  flex-grow: ${(props) => (props.isPriority ? 1 : 0)};
  flex-shrink: 0;

  width: ${(props) => (props.showMap ? "300px" : "100%")};
  height: 200px;
  z-index: 1;
  height: auto;
  @media (min-width: 1024px) {
    max-width: ${(props) => (props.showMap ? "50%" : "100%")};
  }

  @media (min-width: 769px) and (max-width: 1023px) {
    width: 100%;
    height: auto;
  }

  @media (min-width: 600px) and (max-width: 768px) {
    height: auto;
    width: ${(props) => (props.showMap ? "220px" : "100%")};
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;



export const OuterShell = styled.div`
  contain: none;
  overflow: hidden;
  position: relative;
  mask-image: -webkit-radial-gradient(white, black);
  border-radius: 12px;

  &::after {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }
`;

export const ImageScroller = styled.div`
  background: rgb(235, 235, 235) none repeat scroll 0% 0%;
  padding-top: 66.6667%;
  position: relative;
  width: 100%;
  z-index: 0;
`;

export const ImageNavigationHolder = styled.div`
  position: absolute;
  inset: 0px;
  height: 100%;
  width: 100%;
`;

export const ImageHolder = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ImageAnchor = styled.a`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export const InnerImage1 = styled.div`
  height: 100%;
  width: 100%;
  white-space: nowrap;
`;

export const InnerImage2 = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
`;

export const InnerImage3 = styled.div`
  display: inline-block;
  vertical-align: bottom;
  height: 100%;
  width: 100%;
  min-height: 1px;
  border-radius: 0px;
  color: rgb(235, 235, 235);
  filter: ${(props) => (props.blur ? "blur(4px)" : "")};
`;

export const CardImg = styled.img`
  height: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const NavigationDotsHolder = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
  transform: ${(props) =>
    props.translateValue ? `translateX(${props.translateValue})px` : null};
`;

export const NavigationInnerDiv = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const ScrollPreventer = styled.div`
  display: flex;
  overflow: hidden;
  padding-bottom: 12px;
  max-width: 55px;
  overflow: clip;
`;

// This one below will need a dynamic transform translate property depending on the number of dots and the shift needed
export const DotsHolder = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  transform: translateX(0px);
`;

export const NavButtonHolder = styled.div`
  pointer-events: none;
  top: 0px;
  width: 100%;
  height: 100%;
  /* height: 1px;
	width: 1px; */
  position: absolute;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  border: 0px none;
  z-index: 3;
  clip: auto;
  /* clip: rect(0px, 0px, 0px, 0px);
	clip-path: inset(100%); */

  /* ${PhotoHolder}:hover & {
       	width: 100%;
		height: 100%;
		clip: auto;

    } */
`;

export const NavButtonDiv2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent none repeat scroll 0% 0%;
  pointer-events: none;
`;

export const NavButtonLeft = styled.div`
  position: absolute;
  top: 50%;
  pointer-events: auto;
  transform: translateY(-50%);
  left: 8px;

  /* &:active {
		border-color: rgba(0, 0, 0, 0.08);
		background-color: rgb(255, 255, 255);
		color: rgb(0, 0, 0);
		box-shadow: none;
		transform: scale(1);
	} */
  ${ImageHolder}:hover & {
    animation-duration: 0.3s;
    animation-name: ${keyframe_18jn58a};
    opacity: 1;
    visibility: visible;
  }
`;

export const NavButtonRight = styled.div`
  position: absolute;
  top: 50%;
  pointer-events: auto;
  transform: translateY(-50%);
  right: 8px;

  /* &:active {
		border-color: rgba(0, 0, 0, 0.08);
		background-color: rgb(255, 255, 255);
		color: rgb(0, 0, 0);
		box-shadow: none;
		transform: scale(1);
	} */

  ${ImageHolder}:hover & {
    animation-duration: 0.3s;
    animation-name: ${keyframe_18jn58a};
    opacity: 1;
    visibility: visible;
  }
`;

export const NavButton = styled.button`
  display: inline-flex;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  outline: rgb(34, 34, 34) none medium;
  margin: 0px;
  padding: 0px;
  color: rgb(34, 34, 34);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  touch-action: manipulation;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: transform 0.25s ease 0s, transform 0.25s ease 0s,
    transform 0.25s ease 0s;
  width: ${(props) => (props.size ? props.size : "32px")};
  height: ${(props) => (props.size ? props.size : "32px")};

  &:active {
    background-color: #ceedff;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  /* height: 200px; */
  height: auto;
  margin-left: ${(props) => (props.showMap ? "16px" : "0px")};
  margin-top: ${(props) => (props.showMap ? "0px" : "10px")};
  

  overflow: hidden;

  @media (min-width: 769px) and (max-width: 1023px) {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
    height: auto;
  }

  @media (min-width: 600px) and (max-width: 768px) {
    height: auto;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }

  @media (min-width: 1440px) {
    max-width: ${(props) => (props.isPriority && props.showMap ? "50%" : "auto")};
  }
`;

export const SubjectSummarySave = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const SubjectSummary = styled.div`
  flex: 1 1 0%;
  margin-right: 16px;
  width: 90%;
`;

export const Summary = styled.div`
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const SummaryText = styled.div`
  color: rgb(113, 113, 113);
  flex: 1 1 0%;
  line-height: 18px;
  /* max-height: 18px; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  animation-duration: 0.3s;
  animation-name: ${keyframe_18jn58a};
  animation-timing-function: ease-in-out;
  opacity: 1;
`;

export const Subject = styled.div`
  display: flex;
  align-items: center;
`;

export const SubjectSpan = styled.span`
  line-height: 24px;
  max-height: ${(props) => (props.isPriority ? "auto" : "24px")};;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222222;
  word-break: "break-all";

  font-size: 16px;
  line-height: 24px;
  white-space: "nowrap";

  /* white-space: nowrap; */
  width: 100%;
`;

export const SaveButton = styled.button`
  display: inline-block;
  border-radius: 50%;
  border: medium none;
  outline: #222222 none medium;
  margin: 0px;
  padding: 0px;
  color: #222222;
  cursor: pointer;
  touch-action: manipulation;
  position: relative;
  background: transparent none repeat scroll 0% 0%;
  transition: transform 0.25s ease 0s, transform 0.25s ease 0s,
    transform 0.25s ease 0s;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  &:hover::before {
    background: rgb(247, 247, 247) none repeat scroll 0% 0%;
  }
`;

export const SaveSpan = styled.span`
  position: relative;
`;

export const MiniSeparator = styled.div`
  margin-top: 11px;
  width: 32px;
  border-top: 1px solid rgb(221, 221, 221);
`;

export const Details = styled.div`
  margin-top: 9px;
  color: rgb(113, 113, 113);
  font-weight: 400;
  min-height: 18px;
  font-size: 14px;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
  overflow: clip;
  text-overflow: ellipsis;
`;

export const BottomDetails = styled.div`
  margin-bottom: 5px;
  color: rgb(113, 113, 113);
  font-weight: 400;
  min-height: 18px;
  font-size: 14px;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
  overflow: clip;
  text-overflow: ellipsis;
  margin-top: 10px;

  /* @media(max-width: 600px) {
		margin-top: 10px;
	} */
`;

export const DetailSpan = styled.span`
  color: #717171;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const PriceSpan = styled.span`
  line-height: 24px;
  max-height: 24px;
  color: #222222;
  font-size: 18px;
  line-height: 24px;
  margin-top: 12px;
`;

export const BlockSpan = styled.span`
  line-height: 24px;
  max-height: 24px;
  color: #222222;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Segoe UI", sans-serif;
  margin-top: 12px;
  display: flex;
  align-items: center;
`;
