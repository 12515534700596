import Link from "next/link";

import {
  Overall,
  Grid,
  GridChild,
  Separator,
  SeparatorHolder,
  MainCard,
  CardAnchor,
  PhotoHolder,
  OuterShell,
  ImageScroller,
  ImageNavigationHolder,
  ImageHolder,
  ImageAnchor,
  InnerImage1,
  InnerImage2,
  InnerImage3,
  NavigationDotsHolder,
  NavigationInnerDiv,
  ScrollPreventer,
  NavButton,
  NavButtonRight,
  ContentHolder,
  NavButtonHolder,
  NavButtonDiv2,
  NavButtonLeft,
  SubjectSummarySave,
  SubjectSummary,
  Summary,
  SummaryText,
  SubjectSpan,
  Subject,
  MiniSeparator,
  Details,
  DetailSpan,
  PriceSpan,
  BottomDetails,
  BlockSpan,
  SaveButton,
  SaveSpan,
} from "./ListingCard2point0Styles";

import * as Sentry from "@sentry/nextjs";

import "./2point0.module.css";
import {
  MdChevronLeft,
  MdChevronRight,
  MdVerified,
} from "react-icons/md";

import NumberFormat from "react-number-format";
import slugify from "slugify";
import { storage } from "../../src/config/firebase";
import { useEffect, useState } from "react";
import placeholder from "../../public/images/placeholder-image.svg";
export const provinceShorter = (province) => {
  if (province === "Ontario") return "ON";
  if (province === "Alberta") return "AB";
  if (province === "British Columbia") return "BC";
  if (province === "Manitoba") return "MB";
  if (province === "New Brunswick") return "NB";
  if (province === "Newfoundland and Labrador") return "NL";
  if (province === "Northwest Territories") return "NWT";
  if (province === "Nova Scotia") return "NS";
  if (province === "Prince Edward Island") return "PEI";
  if (province === "Québec") return "QC";
  if (province === "Quebec") return "QC";
  if (province === "Nunavut") return "NT";
  if (province === "Saskatchewan") return "SK";
  if (province === "Yukon") return "YT";
  else return "";
};
import useWindowSize from "../Utility/windowSize";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Image as ChakraImage,
  Skeleton,
} from "@chakra-ui/react";
import { useAuth } from "../../utils/auth/newAuth";
import { useMutation } from "react-query";
import { AiFillUnlock } from "react-icons/ai";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { BsBookmark } from "react-icons/bs";
import { clientError } from "../../utils/utilties";
import { User } from "@sentry/nextjs";
import pro from "../../pages/pro";
import { SmallFancy, SmallFancyBold } from "../../styles/ListingStyles";
import { AgentName } from "./ListingCardStyles";

const ListingCard = (props) => {
  const size = useWindowSize();
  const [translateValue, setTranslateValue] = useState(0);
  const [activeImg, setActiveImg] = useState(0);
  //   Active Img
  // - Defaults to 0
  // - next button increases it
  // - previous button descreases
  // - When active equals 0, left not visible
  // - When active equals length -1, right not visible
  const {
    noSeparator,
    showMap,
    triggerAuth,
    order,
    id,
    subject,
    subType,
    propertyTypes,
    type,
    province,
    priceType,
    city,
    price,
    timeStamp,
    primaryImg,
    bedrooms,
    priority,
    showAddress,
    unpriced,
    offMarket,
    imageOrder,
    imageURLs,
    imagesFresh,
    sqft,
    lotSize,
    lotSizeUnit,
    distressed,
    cap,
    primaryImgUrl: primary,
    User: { brokerage, industryRole, fullName, photoURL } = "",
  } = props;
  const [imageArray, setImageArray] = useState(
    imagesFresh && imageURLs?.length > 0 ? imageURLs : null
  );

  useEffect(() => {
    if (imagesFresh && imageURLs?.length > 0) {
      setImageArray(imageURLs)
    } else {
      setImageArray(null)
    }
  }, [imageURLs, imagesFresh])

  String.prototype["toProperCase"] = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  async function updateListingRequest(receivedData) {
    const response = await fetch(`/api/listings/${id}/update?imagesOnly=true`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ updatedData: receivedData }),
    });

    const data = await response.json();
    const { listing } = data;

    return listing;
  }

  const updateImages = useMutation(updateListingRequest, {
    onError: (error) => {
      // An error happened!
      clientError(error);
    },
  });

  const isPriority = priority > 1;

  const imageGetter = async () => {
    // Try to fetch existing URLs if they exist
    if (imageURLs?.length > 0 && imagesFresh) {
      setImageArray(imageURLs);
    } else {
      const listingRef = ref(storage, `listingImages/${id}/compressed`);
      let periodIndex = primaryImg?.lastIndexOf(".");
      let primaryImageName = primaryImg?.substring(0, periodIndex);
      let primaryImageExt = primaryImg?.substring(periodIndex);

      let returnedFiles = await listAll(listingRef);
      let returnedItems = returnedFiles.items;

      // let localPrimary

      if (returnedItems.length > 0) {
        if (imageOrder?.length > 0) {
          // Go through every item in the image order and add it to the array

          let promiseArray = [];
          imageOrder.forEach(async (f) => {
            returnedItems
              .filter(it => it.name.includes('700x700'))
              .forEach((it) => {
                const fileName = it.name.split(/\.(?=[^\.]+$)/)[0]
                fileName.replace('_700x700', "")
                if (
                  fileName ===
                    f?.split(/\.(?=[^\.]+$)/)[0] + "_700x700" ||
                  fileName ===
                    f?.split(/\.(?=[^\.]+$)/)[0]
                ) {
                  promiseArray.push(getDownloadURL(it));
                }
              });
          });

          Promise.all(promiseArray).then((downloadURLs) => {
            setImageArray(downloadURLs);
            updateImages.mutate({
              imageURLs: downloadURLs,
            });
            // If you've figured them out, update the listing with the URLs
          });
        } else {
          // Multiple photos
          let imagePromises = returnedItems.reduce(
            (accumulator, currentValue) => {
              if (currentValue.name.includes("_700x700")) {
                //loading up only x700 files

                if (
                  currentValue.name !==
                  `${primaryImageName}_700x700${primaryImageExt}`
                ) {
                  // if image isn't the primary image
                  accumulator.push(getDownloadURL(currentValue)); // move it to the initial point of the array
                } else {
                  accumulator.unshift(getDownloadURL(currentValue));
                }
              }
              return accumulator;
            },
            []
          );

          Promise.all(imagePromises).then((downloadURLs) => {
            setImageArray([...downloadURLs]);
            updateImages.mutate({
              imageURLs: downloadURLs,
            });
          });
        }
      } else {
        setImageArray([placeholder]);
        return;
      }
    }
  };

  useEffect(() => {
    if (!imageArray) {
      imageGetter();
    }
  }, []);

  const { user } = useAuth();
  // const blocked = !user && order != 0 && (order % 3 == 0 || offMarket);
  const blocked = null;

  return (
    <Overall>
      <Grid
        onClick={() => {
          // Trigger this if user not signed in and 3rd listing in the search box
          if (blocked) {
            triggerAuth({
              hook: "Click on listing card from search",
              header:
                "Get unlimited free access to Canada's hottest commercial listings.",
            });
          }
        }}
      >
        <GridChild>
          <div itemType="http://schema.org/ListItem" itemProp="itemListElement">
            <meta itemProp="name" content={subject} />
            {/* <meta itemProp="url" content={`https://zonado.com/listings/${id}/${slug}`}></meta> */}
            <SeparatorHolder noSeparator={noSeparator}>
              <Separator />
            </SeparatorHolder>

            <MainCard showMap={showMap}>
              {/* Only give them the link if either the user is present or if the card order number is not divisible by 3 */}
              {!blocked && (
                <Link
                  passHref
                  href={`/listings/${encodeURIComponent(id)}/${slugify(
                    subject?.toProperCase() || ""
                  )}`}
                  prefetch={false}
                >
                  <CardAnchor target={size.width > 1000 ? "_blank" : "_self"}/>
                </Link>
              )}
              <PhotoHolder showMap={showMap} isPriority={isPriority}>
                <OuterShell>
                  <div style={{ position: "relative" }}>
                    {offMarket  && (
                      <Badge
                        position="absolute"
                        top="5px"
                        background="white"
                        fontWeight={600}
                        opacity={85}
                        zIndex={1}
                        boxShadow="0px 4px 4px rgb(0 0 0 / 25%)"
                        right="5px"
                      >
                        NOT ON MLS®
                      </Badge>
                    )}
                    <ImageScroller>
                      <ImageNavigationHolder>
                        <span>
                          <ImageHolder>
                            {!blocked && (
                              <Link
                                href={`/listings/${encodeURIComponent(
                                  id
                                )}/${slugify(subject?.toProperCase() || "")}`}
                                passHref
                                prefetch={false}
                              >
                                <ImageAnchor
                                  aria-hidden="true"
                                  target={size.width > 1000 ? "_blank" : "_self"}
                                  tabindex="-1"
                                  aria-label={subject}
                                />
                              </Link>
                            )}

                            <InnerImage1>
                              <InnerImage2>
                                <InnerImage3 blur={blocked}>
                                  {/* This image defaults to the primary img upon load and doesn't wait for the whole array to get filled out to land on the 0 index */}
                                  {imageArray && (
                                    <ChakraImage
                                      loading="lazy"
                                      height="100%"
                                      src={
                                        activeImg === 0 && primary
                                          ? primary
                                          : imageArray[activeImg]
                                      }
                                      alt={subject}
                                    />
                                  )}
                                  <Skeleton
                                    height="100%"
                                    width="100%"
                                    isLoaded={imageArray?.length > 0}
                                  />
                                </InnerImage3>
                              </InnerImage2>
                            </InnerImage1>
                          </ImageHolder>
                        </span>
                        <NavigationDotsHolder>
                          <NavigationInnerDiv>
                            <ScrollPreventer>
                              <div
                                style={{
                                  alignItems: "flex-end",
                                  display: "flex",
                                  justifyContent: "center",
                                  listStyleType: "none",
                                  margin: "0px",
                                  padding: "0px",
                                  transition:
                                    "transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s",
                                  transform: `translateX(${translateValue}px)`,
                                }}
                              >
                                {/* Scale Logic
                                                            if activeimg is >=2 and less
                                                            if index of the dot is index + 2 or index - 2, 0.83333 scale
                                                            if index of the dot is >= index + 3 or <= index - 3, 0.67777 scale

                                                            */}
                                {/* Inactive and active based on image being viewed */}
                                {imageArray?.map((item, index) => (
                                  <span
                                    key={index}
                                    className={
                                      index === activeImg
                                        ? "activeDot"
                                        : "inactiveDot"
                                    }
                                    style={{
                                      transform: `scale(${
                                        activeImg >= 2 &&
                                        activeImg < imageArray?.length - 3
                                          ? index === activeImg + 2 ||
                                            index === activeImg - 2
                                            ? 0.83333
                                            : index >= activeImg + 3 ||
                                              index <= activeImg - 3
                                            ? 0.677777
                                            : 1
                                          : 1
                                      })`,
                                    }}
                                  />
                                ))}
                              </div>
                            </ScrollPreventer>
                          </NavigationInnerDiv>
                        </NavigationDotsHolder>
                      </ImageNavigationHolder>
                    </ImageScroller>

                    {imageArray?.length >= 2 && (
                      <NavButtonHolder>
                        <NavButtonDiv2>
                          {activeImg !== 0 && (
                            <NavButtonLeft
                              onClick={() => {
                                if (
                                  activeImg >= 3 &&
                                  activeImg < imageArray?.length - 2
                                ) {
                                  setTranslateValue(translateValue + 11);
                                }
                                setActiveImg(activeImg - 1);
                              }}
                            >
                              <NavButton>
                                <span>
                                  <MdChevronLeft size="24px" />
                                </span>
                              </NavButton>
                            </NavButtonLeft>
                          )}

                          {activeImg !== imageArray?.length - 1 && (
                            <NavButtonRight
                              onClick={() => {
                                if (
                                  activeImg >= 2 &&
                                  activeImg < imageArray?.length - 3
                                ) {
                                  setTranslateValue(translateValue - 11);
                                }
                                setActiveImg(activeImg + 1);
                              }}
                            >
                              <NavButton>
                                <span>
                                  <MdChevronRight size="24px" />
                                </span>
                              </NavButton>
                            </NavButtonRight>
                          )}
                        </NavButtonDiv2>
                      </NavButtonHolder>
                    )}
                  </div>
                </OuterShell>
              </PhotoHolder>

              <ContentHolder showMap={showMap} isPriority={isPriority}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SubjectSummarySave>
                    <SubjectSummary>
                      <Summary>
                        <SummaryText>
                            {propertyTypes?.join(", ")}{" "}
                            {!propertyTypes?.includes("Sale of Business")
                            ? `${type.toLowerCase()}`
                            : ""}{" "}
                          
                          {city
                            ? `in ${city}${
                                province ? ", " + provinceShorter(province) : ""
                              }`
                            : ""}
                        </SummaryText>
                      </Summary>
                      <Subject>
                        <SubjectSpan isPriority={isPriority}>{subject?.toProperCase()}</SubjectSpan>
                      </Subject>
                    </SubjectSummary>

                    {/* <SaveButton>
                          <SaveSpan>
                              <BsBookmark size="24px" />
                          </SaveSpan>
                      </SaveButton> */}
                  </SubjectSummarySave>

                  <MiniSeparator />

                  {(size.width > 1024 || size.width < 768) && (
                    <Details>
                      <DetailSpan>
                        {/* sqft, lot size, cap, beds, baths */}

                        {sqft && (
                          <DetailSpan>
                            <NumberFormat
                              value={sqft}
                              thousandSeparator={true}
                              displayType={"text"}
                              suffix={" Sq Ft"}
                            />
                          </DetailSpan>
                        )}

                        {lotSize && lotSizeUnit && (
                          <>
                            {sqft && <span aria-hidden="true"> · </span>}
                            <DetailSpan>
                              <NumberFormat
                                value={lotSize}
                                prefix="Lot: "
                                thousandSeparator={true}
                                displayType={"text"}
                                suffix={` ${lotSizeUnit}`}
                              />
                            </DetailSpan>
                          </>
                        )}
                        {cap && (
                          <>
                            <span aria-hidden="true"> · </span>
                            <DetailSpan>{cap}% CAP</DetailSpan>
                          </>
                        )}
                        {bedrooms && (
                          <>
                            <span aria-hidden="true"> · </span>
                            <DetailSpan>{bedrooms} beds</DetailSpan>
                          </>
                        )}
                      </DetailSpan>
                    </Details>
                  )}

                  {blocked ? (
                    <BlockSpan>
                      <AiFillUnlock
                        color="#125f3f"
                        style={{ display: "inline", marginRight: "8px" }}
                      />{" "}
                      <Button
                        marginRight="4px"
                        verticalAlign={"inherit"}
                        paddingY="0px"
                        marginY="0px"
                        onClick={() => {
                          triggerAuth({
                            hook: "Click on listing card from search (sign in link)",
                            header:
                              "Get unlimited free access to Canada's hottest commercial listings.",
                          });
                        }}
                        display="inline"
                        variant="link"
                        fontSize={"18px"}
                        colorScheme="green"
                      >
                        Sign in to view, it's free
                      </Button>
                    </BlockSpan>
                  ) : (
                    <PriceSpan>
                      {unpriced || !price ? (
                        "Unpriced"
                      ) : (
                        <NumberFormat
                          value={price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          suffix={type === "For Lease" ? `/${priceType}` : null}
                        />
                      )}
                    </PriceSpan>
                  )}
                </div>

                {distressed && 
                <div>

                  <Badge 
                  color="#fff"
                  colorScheme='red'>distressed sale</Badge>
                </div>
                }

                {priority > 1 ? 
                <Flex alignItems="center" mt="12px">
                  <Avatar
                    mr="8px"
                    size="sm"
                    src={photoURL || ""}
                  />
                  <Flex alignItems={'center'}>
                    <AgentName>{fullName} </AgentName>
                    <Flex alignItems={'center'} ml="3px">
                      <MdVerified color='#125f3f' />
                    </Flex>
                  </Flex>
                </Flex>
                : null}
                <BottomDetails>

                  {brokerage && (
                    <>
                      {/* <span aria-hidden="true"> · </span> */}
                      <DetailSpan>{brokerage.toProperCase()}</DetailSpan>
                    </>
                  )}
                </BottomDetails>
              </ContentHolder>
            </MainCard>
          </div>
        </GridChild>
      </Grid>
    </Overall>
  );
};

export default ListingCard;
