import styled from 'styled-components'

export const ListingCardHolder = styled.div`
    height:100%;
    overflow:hidden;
    border:1px solid #d8d8d8;
    transition: all 0.3s ease;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
        border: 1px solid #125F3F;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
`

export const CardHolder = styled.div`
    width: 560px;
    background-color: white;
    display: flex;
    border: 0.5px solid #00000080;
    padding: 4px;
    position: relative;
    margin-bottom: 30px;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    max-height: 117px;

    &:hover {
        cursor: pointer;
        border: 1px solid #00000080;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
    }
`

export const DataHolder = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    box-sizing: border-box;
    width: 70%;
`

export const ImgHolder = styled.div`
    width: 25%;
    height: 115px;
    margin-right: 15px;
`

export const ListingImg = styled.img`
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
`

export const DataLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`

export const TimeTag = styled.div`
    width: 115px;
    display: flex;
    justify-content: flex-end;
`
export const HeadingTag = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`

export const TypeBadge = styled.div`
    border-radius: 50%;
    background-color: ${props => props.color};
    position: absolute;
    right: 3px;
    top: 3px;
    padding: 3px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px;
`

export const TypeMetrics = styled.div`
    width: 85px;
    display: flex;
    justify-content: space-between;
`

export const BedBath = styled.div`
    width: 35px;
    width: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const BoxImgHolder = styled.div`
    width: 100%;
    /* Below removed Mar 10 '21 */
    object-fit: cover;
    height: "auto";
    color: white;
    // background-color: #8080804d;
    min-height: 140px;

    @media (max-width: 500px) {
        min-height: 180px
    } 

    /* @media (max-width: 1248px) {
        height: 300px;
    }

    @media (max-width: 768px) {
        height: 350px
    }

    @media (max-width: 500px) {
        height: 250px
    } */
`

export const BoxImgHolderHome = styled.div`
    width: 100%;
    height: 200px;
    object-fit: cover;
    /* height: 200px; */
`

export const RelevantListingBox = styled.div`
    border-radius: 10px;
    width: 220px;
    border: 1px solid darkgrey;
    transition: all 0.3s ease;
    margin-right: 20px;
    height: 100%;

     &:hover {
        cursor: pointer;
        border: 1px solid #125F3F;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
`

export const PseudoListingBox = styled.div`
    border-radius: 10px;
    width: 220px;
    border: 1px solid #d8d8d8;
    min-width: 220px;
`

export const SubjectLine = styled.h3`
    margin-top: 1px;
    white-space: normal;
    color: #4a4a4a;
    line-height: 1.3;
    margin-bottom: 7px;
`

export const RelevantImg = styled.div`
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    width: 100%;
    object-fit: cover;
    height: "auto";
    color: white;
`

export const Bolder = styled.span`
    font-weight: bold;
`

export const BoldUnderline = styled.span`
    font-weight: bold;
    text-decoration: underline;
`

export const Lighter = styled.span`
    color: gray;
`

export const AgentName = styled.div`
  font-family: "Jarkarta", sans-serif;
  font-size: 16px;
  font-weight: 100;
`